import React from 'react';
import PropTypes from 'prop-types';
import {gql} from 'apollo-boost';

import ProductPreview, {PRODUCT_FRAGMENT} from '../../../component/productPreview/ProductPreview';

import {PRODUCT_DETAILS_PRELOAD_FRAGMENT} from "../../product/Product";

import './Like.css';
import ProductsList from '../../../component/productsList/ProductsList';

// GraphQL queries
const SEE_USER_LIKES = gql`
    query SeeUserLikes($userId: Int!, $offset: Int!, $limit: Int!) {
        seeUserLikes(user_id: $userId, offset: $offset, limit: $limit) {
            ...ProductFragment
            ...ProductDetailsPreloadFragment
        }
    }
    ${PRODUCT_FRAGMENT}
    ${PRODUCT_DETAILS_PRELOAD_FRAGMENT}
`;

/**
 * Seller like tab component.
 */
export default function Like({ seller }) {
    // build the UI elements
    const renderProduct = product => (
            <div className="Like-1by1" key={product.id}>
                <div className="Like-1by1-content">
                    <ProductPreview product={product}/>
                </div>
            </div>
        )

    return (
        <section className="Like">
                <ProductsList itemsProperty="seeUserLikes"
                              query={SEE_USER_LIKES}
                              variables={{
                                      userId: Number(seller.user.id)
                                  }}
                              renderProduct={renderProduct} />
        </section>
    );
}
Like.propTypes = {
    seller: PropTypes.shape({
        user: PropTypes.shape({
            id:  PropTypes.string.isRequired
        })
    }).isRequired
};

