import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

const GET_CATEGORIES_CACHED = gql`
    query getCategoriesCached {
        getCategoriesCached(limit: 1000) {
            name
            subcategories {
                id
                name
            }
        }
    }
`;

const EXISTS_BRAND_BY_NAME = gql`
    query existsBrandByName($query: String!) {
        existsBrandByName(query: $query)
    }
`;

/**
 * 검색시 sortValue 를 지정
 * @param searchQuery
 * @returns {{loading: boolean}|{sortValue: string, loading: boolean}|{sortValue: (string), loading: boolean}}
 */
export const useSortValue = (searchQuery) => {
    // 브랜드 매치 체크
    const { data: brandData, loading: brandLoading, error: brandError } = useQuery(EXISTS_BRAND_BY_NAME, {
        variables: { query: searchQuery || "" },
        skip: !searchQuery,
        ssr: true,
        fetchPolicy: 'network-only'
    });

    // 카테고리 매치 체크 - 항상 호출
    const { data: categoriesData, loading: categoriesLoading, error: categoryError } = useQuery(GET_CATEGORIES_CACHED, {
        ssr: true,
        fetchPolicy: 'cache-first'
    });

    const checkCategoryMatch = (categories) => {
        if (!searchQuery || !categories) return false;

        const sanitizedSearchQuery = searchQuery.replace(/\s+/g, '');
        
        // 메인 카테고리 체크
        if (categories.some(category => {
            if (!category.name) return false;
            const sanitizedCategory = category.name.replace(/\s+/g, '');
            return sanitizedCategory.includes(searchQuery) || 
                   sanitizedCategory.includes(sanitizedSearchQuery);
        })) {
            return true;
        }

        // 서브 카테고리 체크
        return categories.some(category => 
            category.subcategories && 
            category.subcategories.some(sub => {
                if (!sub.name) return false;
                const sanitizedSubCategory = sub.name.replace(/\s+/g, '');
                return sanitizedSubCategory.includes(searchQuery) || 
                       sanitizedSubCategory.includes(sanitizedSearchQuery);
            })
        );
    };

    // 로딩 상태 체크
    if (brandLoading || categoriesLoading) {
        return { loading: true };
    }

    // 에러 체크
    if (brandError || categoryError) {
        return { 
            sortValue: 'RELEVANCE', 
            loading: false
        };
    }

    // 브랜드 매치 체크
    if (brandData && brandData.existsBrandByName) {
        return { sortValue: 'POPULAR', loading: false };
    }

    // 카테고리 매치 체크
    const hasCategoryMatch = checkCategoryMatch(categoriesData.getCategoriesCached);

    return {
        sortValue: hasCategoryMatch ? 'POPULAR' : 'RELEVANCE',
        loading: false
    };
};
